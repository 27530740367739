export default {
    init(hero: HTMLElement) {
        if (!hero) return;

        const button = hero.querySelector('.scroll-to-next-section');
        const nextSection = hero.nextElementSibling;

        if (!nextSection || !button) return;

        button.addEventListener('click', function () {
            nextSection.scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
};
