import './app.scss';
import 'lazysizes';
import scrollToNextSection from './ts/scroll-to-next-section';
import heroAnimations from './ts/hero-animations';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const heroHomeSections = document.querySelectorAll(
        '.hero-home'
    ) as NodeListOf<HTMLElement>;
    if (heroHomeSections.length) {
        heroHomeSections.forEach((section) => {
            scrollToNextSection.init(section);
            heroAnimations.init(section);
        });
    }

    const menu = document.getElementById('menu');
    if (menu) {
        import('./ts/navigation').then((navigation) => {
            navigation.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    // Init one map if there is one
    const map: HTMLElement | null = document.querySelector('.section-map');
    if (map) {
        import('./ts/map').then((mapComponent) => {
            mapComponent.default.init(map);
        });
    }

    // Init all category sections if there are some
    const categorySections = document.querySelectorAll(
        '.categories-section'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/categories').then((categories) => {
            categorySections.forEach(function (categorySection) {
                categories.default.init(categorySection);
            });
        });
    }

    const logoBanners = document.querySelectorAll(
        '.logo-banner'
    ) as NodeListOf<HTMLElement>;
    if (logoBanners.length) {
        logoBanners.forEach((component) => {
            import('./ts/banner').then((slidingBanner) => {
                slidingBanner.default.init(component);
            });
        });
    }

    // no element request because element will be generated via js
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });
})();
