import lottie from 'lottie-web';

export default {
    init(wrapper: HTMLElement) {
        let animation;
        if (window.innerWidth > 640) {
            animation = wrapper.querySelector(
                '.lottie-hero-animation-desktop'
            ) as HTMLElement;
        } else {
            animation = wrapper.querySelector(
                '.lottie-hero-animation-mobile'
            ) as HTMLElement;
        }

        if (!animation) return;

        const animationUrl = animation.dataset.animationUrl;

        if (!animationUrl) return;

        const lottieAnimation = lottie.loadAnimation({
            container: animation,
            autoplay: true,
            path: animationUrl,
            loop: false
        });

        if (!lottieAnimation) return;
    }
};
